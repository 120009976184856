<template>
  <div class="mt-5 ml-5">
    <h1>You're beeing redirected to the COBI.time Documentation</h1>
    <h1 style="cursor: pointer" @click="redirect()">
      If the redirect did not work or is taking to long click here
    </h1>
  </div>
</template>

<script>
export default {
  created() {
    window.location.href =
      "https://docs.cobisoft.de/wiki/de/cobi.time/user_manual";
  },
  methods: {
    redirect: function () {
      window.open("https://docs.cobisoft.de/wiki/de/cobi.time/user_manual");
    },
  },
};
</script>

<style>
</style>